import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const RecipesPage = () => (
	<Layout headerColor='yellow'>
      <SEO title="Recipes" />

      <h1>Recipes Index Page Coming Soon</h1>
  	</Layout>
)

export default RecipesPage;